export class CommonFunction{
    
    // remove space relace with '_'
    static removeSpaceAddUnderScore(str){
        return str.replace(/\s/g, '');
    }

    // remove _ relace with ' ' (space)
    static removeUnderScoreAddSpace(str){
        return str.replace(/_/g, ' ');
    }

    static checkLogin(){
        return (localStorage.getItem('user_id'))? true:false; 
    }

    static logOut(){
        if(localStorage.getItem('user_id')){
            //alert("Are you sure You want to log out ?");
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('email');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');
            localStorage.removeItem('mobile');
            localStorage.removeItem('restaurant_name');
            localStorage.removeItem("unique_id");
            return true;
        }else{
            return false;
        }
    }

    static timeConvert(time){
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }

        return time.join ('');
    }


}