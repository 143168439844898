// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// local
//  https://console.firebase.google.com
export const environment = {
  production: true,
  apiUrl: 'https://justsmoke.venuetours.co.uk/admin/public/api/',
  imageUrl: 'https://justsmoke.venuetours.co.uk/admin/public/',
  appUrl: 'https://justsmoke.venuetours.co.uk',
  firebase:{
    apiKey: "AIzaSyBYVKOGFoe5jyi3ZxlfZkMkvppu4TE1VnI",
    authDomain: "just-smoke-1537178641964.firebaseapp.com",
    databaseURL: "https://just-smoke-1537178641964.firebaseio.com",
    projectId: "just-smoke-1537178641964",
    storageBucket: "just-smoke-1537178641964.appspot.com",
    messagingSenderId: "1085083248012",
    appId: "1:1085083248012:web:d0fb523fb67c72b23c62a9"
  },
  googleMapsKey: 'AIzaSyARh12rcI6GG5bSXRiqdosG1Y4kc1YNCSs', // 'AIzaSyA8SDAzIChdp0gS-I3jC-_do4Nft3R-JV8',
};

// live
// https://console.firebase.google.com
/* export const environment = {
  production: false,
  apiUrl:'http://localhost:8000/api/',
  imageUrl:'http://localhost:8000/',
  appUrl:'http://localhost:8000/booking/',
//  appUrl:'http://localhost:4200',
  firebase: {
    apiKey: "AIzaSyAV8CJeqNtRTyJTbzbFHsIZnNFuUb5Jny8",
    authDomain: "justsmoke-60a29.firebaseapp.com",
    databaseURL: "https://justsmoke-60a29.firebaseio.com",
    projectId: "justsmoke-60a29",
    storageBucket: "justsmoke-60a29.appspot.com",
    messagingSenderId: "539606715764"
  },
  googleMapsKey: 'AIzaSyAV8CJeqNtRTyJTbzbFHsIZnNFuUb5Jny8', // 'AIzaSyA8SDAzIChdp0gS-I3jC-_do4Nft3R-JV8',
  //AIzaSyAV8CJeqNtRTyJTbzbFHsIZnNFuUb5Jny8
}; */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
