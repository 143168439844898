import { Component, OnInit } from '@angular/core';
import { CommonFunction } from '../shred/common-function';
import { CartService } from '../services/cart/cart.service';
import { Location } from '@angular/common';

import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  login_user_id: any;
  cart_list: any;
  discount_off: any;
  order_total: any;
  deliveryCharge: any;
  grand_total: any;
  cart_data = false;

  constructor(
    private cartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,

  ) { }

  ngOnInit() {
    this.discount_off = 0;
    this.deliveryCharge = 50;
    this.order_total = 0;
    this.grand_total = 0;
    if (CommonFunction.checkLogin()) {
      this.login_user_id = localStorage.getItem('user_id');
      this.getUserCartData();
    } else {
      localStorage.setItem('opencart', 'close');
    }

    $(document).ready(function () {
      setTimeout(function () {
        $('.add').click(function () {
          $(this).prev().val(+$(this).prev().val() + 1);
        });
        $('.sub').click(function () {
          if ($(this).next().val() > 0) {
            $(this).next().val(+$(this).next().val() - 1);
          }
        });
      }, 2000);
    });


  }

  closeCart() {
    localStorage.setItem('opencart', 'close');
    location.reload();
  }

  goToCheckout() {
    localStorage.setItem('backUrl', this.location.path());
    this.router.navigate(['/checkout']);
    this.closeCart();
  }

  getUserCartData() {
    this.cartService.getUserCartData(this.login_user_id).subscribe(resultArray => {
      const result = resultArray;
      if (result.status === 'success') {
        this.cart_data = true;
        this.cart_list = result.carts;
        this.order_total = 0;
        this.discount_off = 0;
        this.grand_total = 0;
        this.deliveryCharge = 50;
        this.cart_list.forEach(element => {
          this.order_total = parseFloat(element.menuitems.price) + this.order_total;
          this.discount_off = (parseFloat(element.menuitems.price) * 20) / 100 + this.discount_off; // 20% off as per html 
        });
        this.grand_total = this.order_total + this.deliveryCharge - this.discount_off;

      } else {
        this.cart_data = false;
      }
    });
  }

  removeItemFromCart(cart_Id) {
    this.cartService.deleteInUserCart(cart_Id).subscribe(resultArray => {
      const result = resultArray;
      if (result.status === 'success') {
        this.getUserCartData();
      } else {

      }
    });
  }
}
