import { Injectable } from '@angular/core';
import { Http ,Response , Headers, RequestOptions} from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VenuelistService {

  
  public api_url: string = environment.apiUrl;
  public mapApiKey: string = environment.googleMapsKey
  public mapPlaceUrl: string = 'https://maps.googleapis.com/maps/api/place/details/json?placeid=';

  constructor(private _http: Http) { }

  getVenueByZipcode(searchZipCode){
    return this._http.get(this.api_url + 'venueByZipcode/'+searchZipCode).pipe(map((res: Response) => res.json()));
  }

  getVenueByVenuId(venueId){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.get(this.api_url + 'getVenueById/'+venueId ,options).pipe(map((res: Response) => res.json()));
  }

  getRatingFromPlaceId(placeID){
    //https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name,reviews,rating&key=AIzaSyBdMZ12VMOKrmLnSUToRxQPncYkKKr57Po
    //https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name,reviews,rating&key=AIzaSyBdMZ12VMOKrmLnSUToRxQPncYkKKr57Po
    //var headers= new Headers({'Access-Control-Allow-Origin':'*'});
    //var headers = new Headers({ 'Content-Type': 'application/json'});
    //var options = new RequestOptions({ headers: headers });
    return this._http.get('https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name,reviews,rating&key=AIzaSyBdMZ12VMOKrmLnSUToRxQPncYkKKr57Po').pipe(map((res: Response) => res.json()));
  }
}
