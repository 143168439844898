import { Component } from '@angular/core';
import { Router ,NavigationEnd  } from '@angular/router';
import { timeout } from 'q';
import { environment } from '../environments/environment';
import * as _ from 'lodash';
declare var $: any; 
declare var google: any;  
declare var navigator:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public mapKey: string = environment.googleMapsKey;
  title = 'just Smoke';
  currentUrl:any;
  headerDisplay=false;
  footerDisplay=false;
  userlocation:any;
  showcart = false;
  
  constructor(private router:Router) { }

  ngOnInit() {
    // do something with the parameters
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.currentUrl = event.url;  // event.url has current url
        this.diplayHeaderFooter(this.currentUrl);
      }
    });
    this.getLocation();
    $('#load').fadeOut(0);
    // $( document ).ready(function() {
    //   $('#load').fadeOut(3000);
    // });
  }

  getLocation() {
    if (navigator.geolocation) {
        let  newpotion = navigator.geolocation.getCurrentPosition(this.showPosition);
        setTimeout(() => {
          this.getPostalCode();
        }, 20);
    } else {
        console.log("Geolocation is not supported by this browser");
    }
  }

  showPosition(position) {
    //set user location
    localStorage.setItem('latitude', position.coords.latitude);
    localStorage.setItem('longitude', position.coords.longitude);
  }

  getPostalCode(){
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
      var geocoder = new google.maps.Geocoder(); 
      var latlng = new google.maps.LatLng(latitude, longitude); 
      geocoder.geocode({'latLng': latlng}, function(results, status) { 
        results.forEach(element => {
          if ( _.first(element.types) === "postal_code" ) {
            let newZip = JSON.parse(JSON.stringify(_.first(element.address_components))).long_name;
            localStorage.setItem('user_location_zipcode', newZip);
          }
        });
      });
      // $.ajax({
      //   type: "POST",
      //   url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true',
      //   dataType: "json",
      //   beforeSend: function(){
      //   // do something before the request such as show a loading image
      //   },
      //   error: function(err){
      //   // handle errors
      //   },
      //   success: function(data) {
      //     var addresses = data.results; // cache results in a local var
      //     $.each(addresses, function(i){ // iterate through to find a postal code
      //       if(this.types[0]=="postal_code"){ // check that the type is a postal code
      //         var postal=this['address_components'][0]['long_name']; // grab postal string
      //         if(postal.length > 3){ // is the result is more then 3 letter shorthand use it
      //           // do something with your result and then lets break the iteration
      //           console.log("postalcode",postal);
      //           return false;
      //         }
      //       }
      //     });
      //   } // end success
      // }); // end ajax
  }

  diplayHeaderFooter(current_url){
    
    var regExpVenuelist ="/venuelist/"+".*$";
    var regExpAddpeople ="/addpeople/"+".*$";
    var regExpMenu ="/menu/"+".*$";
    var regExpResetpasword ="/resetpasword/"+".*$";
    var regExpReviews ="/reviews";
    var regExpThankyou ="/thankyou";
    var regExpInfo ="/info";
    var regExpEditProfile ="/editprofile";
    var regExpOrderlist ="/orderlist";
    var regExpOrderview ="/orderview";

    if( current_url ==="/map" ||
       current_url.match(regExpVenuelist) || 
       current_url.match(regExpAddpeople) || 
       current_url.match(regExpResetpasword) || 
       current_url.match(regExpMenu) ||
       current_url.match(regExpReviews) ||
       current_url.match(regExpThankyou) ||
       current_url.match(regExpEditProfile) ||
       current_url.match(regExpOrderlist) ||
       current_url.match(regExpOrderview) ||
       current_url.match(regExpInfo) ){
      this.headerDisplay=true;
      this.footerDisplay=true;
    }else{
      this.headerDisplay=false;
      this.footerDisplay=false;
    }

    this.addtoCart();
  }

  addtoCart(){
    if(localStorage.getItem('opencart')){
      if(localStorage.getItem('opencart') === "open"){
        this.showcart = true;
      }else{
        this.showcart = false;
      }
    }else{
      this.showcart = false;
    }
  }
  
}
