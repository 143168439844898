import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// lazy loading
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home',  loadChildren: './home/home.module#HomeModule' },
  { path: 'signin',  loadChildren: './signin/signin.module#SigninModule' },
  { path: 'signup',  loadChildren: './signup/signup.module#SignupModule'},
  { path: 'map',  loadChildren: './map/map.module#MapModule' },
  { path: 'venuelist/:searchZipCode', loadChildren: './venuelist/venuelist.module#VenuelistModule'},
  { path: 'addpeople/:venueId', loadChildren: './addpeople/addpeople.module#AddpeopleModule'},
  { path: 'menu/:venueId', loadChildren: './menu/menu.module#MenuModule'},
  { path: 'editprofile', loadChildren: './editprofile/editprofile.module#EditprofileModule'},
  { path: 'forgotpassword', loadChildren: './forgotpassword/forgotpassword.module#ForgotpasswordModule'},
  { path: 'addtocart', loadChildren: './addtocart/addtocart.module#AddtocartModule'},
  { path: 'billing', loadChildren: './billing/billing.module#BillingModule'},
  { path: 'checkout', loadChildren: './checkout/checkout.module#CheckoutModule'},
  { path: 'timeslot', loadChildren: './timeslot/timeslot.module#TimeslotModule'},
  { path: 'payment', loadChildren: './payment/payment.module#PaymentModule'},
  { path: 'info', loadChildren: './info/info.module#InfoModule'},
  { path: 'reviews', loadChildren: './reviews/reviews.module#ReviewsModule'},
  { path: 'thankyou', loadChildren: './thankyou/thankyou.module#ThankyouModule'},
  { path: 'orderlist', loadChildren: './orderlist/orderlist.module#OrderlistModule'},
  { path: 'orderview/:orderId', loadChildren: './orderview/orderview.module#OrderviewModule'},
  { path: 'resetpassword/:tempToken', loadChildren: './resetpassword/resetpassword.module#ResetpasswordModule'},
  { path: 'confirmation', loadChildren: './confirmation/confirmation.module#ConfirmationModule'},
  { path: '**', loadChildren: './nopage/nopage.module#NopageModule'},
  
];


@NgModule({
  imports: [ RouterModule.forRoot(routes,{
    useHash: true  ,// using # with url change value "true"
    onSameUrlNavigation: 'reload',
    }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
