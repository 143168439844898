import { Injectable } from '@angular/core';
import { Http ,Response ,Headers, RequestOptions} from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  public api_url: string = environment.apiUrl;

  constructor(private _http: Http) { }

  addToCart(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'addcart',data,options).pipe(map((res: Response) => res.json()));
  }

  getUserCartData(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'getcart' , data ,options).pipe(map((res: Response) => res.json()));
  }

  editInUsercart(data,cart_id){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.put(this.api_url + 'updatecart/' + cart_id ,data ,options).pipe(map((res: Response) => res.json()));
  }

  deleteInUserCart(newdata){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'deleteshisha', newdata ,options).pipe(map((res: Response) => res.json()));
  }

  addBilling(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'billing',data,options).pipe(map((res: Response) => res.json()));
  }

  getBilling(user_id){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.get(this.api_url + 'billing/'+ user_id ,options).pipe(map((res: Response) => res.json()));
  }

  checkVenueWithItemInCart(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'checkVenueWithItemInCart',data ,options).pipe(map((res: Response) => res.json()));
  }

  deleteCustomerCartData(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'deleteCustomerCartData',data ,options).pipe(map((res: Response) => res.json()));
  }

  editCustomerCartData(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.get(this.api_url + 'editCustomerCartData/'+data ,options).pipe(map((res: Response) => res.json()));
  }

  updateCustomerCartData(data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.post(this.api_url + 'updateCustomerCartData',data ,options).pipe(map((res: Response) => res.json()));
  }
}
