import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { CommonFunction } from '../shred/common-function';
import { CartService } from '../services/cart/cart.service';
import { Location } from '@angular/common';
import { VenuelistService } from '../services/venuelist/venuelist.service';


declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  searchZipCode: any;
  venueId: any;
  is_login = false;
  login_user_id: any;
  cart_list: any;
  discount_off: any;
  order_total: any;
  deliveryCharge: any;
  grand_total: any;
  cart_data = false;
  cart_products_length: any;
  totalVenue: any;
  venueList: any;
  searchZipCodeDisplay: any;
  currentCity: any;
  displayVenuesText: any;
  venueName: any;
  displayText: any;
  currentUrl: any;
  showHideSearchInputText = false;
  showHideSearchText = true;
  searchForm: FormGroup;
  navHideShow = false;
  navHideShowMobile = false;
  priceArray: any = [];
  max_price_apply: any;
  searchVenueName: any;
  showHideRadTextDataLocation = true;
  showHideRadTextDataVenue = false;
  minShisha: any;
  shishaMessageDispaly: any;
  admin_charge: any;
  hideCartMenuForMobile: any;


  constructor(
    private CartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private VenuelistService: VenuelistService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.searchForm = this.formBuilder.group({
      searchtext: ['', Validators.required],
    })

    this.is_login = CommonFunction.checkLogin();
    this.displayVenuesText = false;
    this.displayText = false;
    var that = this;
    this.cart_products_length = 0;
    if (this.is_login) {
      this.login_user_id = localStorage.getItem('user_id');
      this.getUserCartData();
    } else {
      this.login_user_id = '';
    }

    if (!!localStorage.getItem('searchZipCode')) {
      this.searchZipCode = localStorage.getItem('searchZipCode');
      this.searchZipCodeDisplay = CommonFunction.removeUnderScoreAddSpace(this.searchZipCode);
      this.getVenueByZipcode();
    }

    if (!!localStorage.getItem('search_venue_name')) {
      this.searchZipCode = localStorage.getItem('search_venue_name');
      this.getVenueByZipcode();
      this.getVenueName();
    }



    if (!!localStorage.getItem('searchZipCode') && !!localStorage.getItem('restaurant_name')) {
      this.getVenueName()
    }

    // if(!!this.route.snapshot.paramMap.get('searchZipCode')){
    //   this.searchZipCode = this.route.snapshot.paramMap.get('searchZipCode');
    // }else{
    //   this.searchZipCode = 'All';
    // }

    if (!!this.route.snapshot.paramMap.get('venueId')) {
      this.venueId = this.route.snapshot.paramMap.get('venueId');
    } else {
      this.venueId = 1;
    }

    if (localStorage.getItem('venue_id')) {
      this.venueId = localStorage.getItem('venue_id');
    }

    $(document).ready(function () {

      $('#cart_button').click(function () {
        $('#panel-cart').addClass('show');
        that.getUserCartData();
      });

      $('#cart_button_mobile').click(function () {
        $('#panel-cart').addClass('show');
        that.getUserCartData();
      });
      $('#close-cart').click(function () {
        $('#panel-cart').removeClass('show');
      });
      $('#header_check').click(function () {
        that.getUserCartData();
      });

      $('#searchCountList').click(function () {
        that.getVenueByZipcode();
      });

      $('#searchVenueText').click(function () {
        that.getVenueName();
      });

      $('#searchInputSearchArea').click(function () {
        that.getCurrentUrl();
      });

      $('#showHidenavigationBar').click(function () {
        that.showHideNavigation();
        that.showMobileHideNavigation();
      });

      $('#showHideRadTextId').click(function () {
        that.showHideRadText();
      });

      $(document).click(function (e) {
        var container = $('#removeCartDataExtrabtn, #removeCartDataItembtn, #panel-cart, #cart_button, #cart_button_mobile, #checkout-error-button-ok');
        var hasShowClass = container.hasClass('show')
        var className = e.target.className;
        var targetId = e.target.id

        if (container.has(e.target).length === 0 && hasShowClass &&
          className !== 'cart-value' && className !== 'ti ti-close' &&
          targetId !== 'content' && targetId !== 'cart_button') {
          $('#close-cart').click();
        }
      });

      setTimeout(function () {
        $('.add').click(function () {
          $(this).prev().val(+$(this).prev().val() + 1);
        });
        $('.sub').click(function () {
          if ($(this).next().val() > 1) $(this).next().val(+$(this).next().val() - 1);
        });
      }, 2000);
    });


  }

  showHideRadText() {
    var newcurrentUrl = this.router.url;
    var regExpVenuelist = '/venuelist/' + '.*$';
    var regExpAddpeople = '/addpeople/' + '.*$';
    var regExpmenu = '/menu/' + '.*$';

    if (newcurrentUrl.match(regExpmenu)) {
      this.showHideRadTextDataLocation = false;
      this.showHideRadTextDataVenue = true;
    } else {
      this.showHideRadTextDataLocation = true;
      this.showHideRadTextDataVenue = false;
    }


  }

  searchFormSubmit() {
    if (this.searchForm.valid && !!this.searchForm.value.searchtext) {
      var searchtext = this.searchForm.value.searchtext;
      searchtext = CommonFunction.removeSpaceAddUnderScore(searchtext)
      //searchZipCode = searchZipCode.replace(/\s/g, '_'); // remove space relace with '_'
      setTimeout(() => {
        //this.router.navigate(['venuelist']);
        localStorage.setItem('searchZipCode', '');
        localStorage.setItem('search_venue_name', searchtext);
        this.router.navigate(['venuelist/' + searchtext]);
      }, 100);
    } else {
      this.validateAllFields(this.searchForm);
    }
  }

  getmenu() {
    if (!!this.route.snapshot.paramMap.get('venueId')) {
      this.venueId = this.route.snapshot.paramMap.get('venueId');
    } else {
      if (localStorage.getItem('venue_id')) {
        this.venueId = localStorage.getItem('venue_id');
      } else {
        this.venueId = 1;
      }
    }

  }

  getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.showHideSearchBar(this.currentUrl);
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd ) {
    //     this.currentUrl = event.url;  // event.url has current url
    //     this.showHideSearchBar(this.currentUrl);
    //   }
    // });
  }

  showHideSearchBar(currentUrl) {
    if (currentUrl === '/map') {
      this.showHideSearchInputText = true;
      this.showHideSearchText = false;
    } else {
      this.showHideSearchInputText = false;
      this.showHideSearchText = true;
    }

    if (currentUrl === '/editprofile') {
      this.showHideSearchText = false;
    }
  }


  showHideNavigation() {
    var currentUrl = this.router.url;

    var regExpVenuelist = '/venuelist/' + '.*$';
    var regExpAddpeople = '/addpeople/' + '.*$';

    if (currentUrl === '/map' || currentUrl === '/home' || currentUrl.match(regExpVenuelist) || currentUrl.match(regExpAddpeople)) {
      this.navHideShow = true;
    } else {
      this.navHideShow = false;
    }
  }

  showMobileHideNavigation() {
    var currentUrl1 = this.router.url;
    var regExpVenuelist = '/venuelist/' + '.*$';
    var regExpAddpeople = '/addpeople/' + '.*$';

    if (currentUrl1.match(regExpVenuelist)) {
      this.hideCartMenuForMobile = true;
    } else {
      this.hideCartMenuForMobile = false;
    }

    if (currentUrl1 === '/map' || currentUrl1 === '/home' || currentUrl1.match(regExpVenuelist) || currentUrl1.match(regExpAddpeople)) {
      this.navHideShowMobile = true;
    } else {
      this.navHideShowMobile = false;
    }
  }

  getVenueName() {
    if (!!localStorage.getItem('restaurant_name')) {
      this.displayVenuesText = false;
      this.displayText = true;

      this.venueName = localStorage.getItem('restaurant_name');
      this.searchZipCode = localStorage.getItem('searchZipCode');
      this.searchZipCodeDisplay = CommonFunction.removeUnderScoreAddSpace(this.searchZipCode);
    }
  }

  goToCheckout() {
    //localStorage.setItem('backUrl',this.location.path());
    this.router.navigate(['/checkout']);
  }

  editCart(cart, index, type) {
    $('#deleteItemFromCart i').addClass('fa fa-spinner fa-spin');
    var index = index;
    var qty_id = '#qty' + cart.id;
    var Qty_value = $(qty_id).val();
    if (type === 'sub') {
      Qty_value = parseInt(Qty_value) - 1;
    }
    if (type === 'add') {
      Qty_value = parseInt(Qty_value) + 1;
    }

    var cart_id = cart.id;

    var cart_data = {
      menuitem_id: cart.menuitem_id,
      customer_id: cart.customer_id,
      venu_id: cart.venu_id,
      total_person: cart.total_person,
      quantity: Qty_value,
      time: cart.time,
    }

    this.CartService.editInUsercart(cart_data, cart_id).subscribe(resultArray => {
      $('#deleteItemFromCart i').removeClass('fa fa-spinner fa-spin');
      var result = resultArray;
      if (result.status === 'success') {
        this.getUserCartData();
      } else {

      }
    })

  }

  logout() {
    CommonFunction.logOut();

    setTimeout(() => {
      this.router.navigate(['signin']);
    }, 500)
  }

  removeItemFromCart(cart_Id, shisha_Id) {
    $('#deleteItemFromCart i').addClass('fa fa-spinner fa-spin');
    var newdata = { 'option_id': cart_Id, 'shisha_id': shisha_Id }
    this.CartService.deleteInUserCart(newdata).subscribe(resultArray => {
      var result = resultArray;
      $('#deleteItemFromCart i').removeClass('fa fa-spinner fa-spin');
      setTimeout(() => {
        $('#content').click(); // for menu page get usercartdata
        this.getUserCartData();
      }, 100);

      // if(result.status === 'success'){
      //   this.getUserCartData();
      // }else{

      // }
    })
  }

  hideCartValidationPopUp() {
    $('#checkoutValidationPopup').hide();
    $('#close-cart').click();
    $('#add_to_cart_button_menu').click();
    setTimeout(() => {
      this.cart_list.forEach(element_new => {
        var diplay = false;
        element_new.cart_options_menu.forEach(element => {
          var priceId = '#price' + element.id;
          if (element.price == Math.max.apply(null, element_new.priceArray) && diplay == false) {
            $(priceId).show();
            diplay = true;
          } else {
            $(priceId).hide();
          }
        });
      })
    }, 0);
  }

  getUserCartData() {
    this.is_login = CommonFunction.checkLogin();

    if (localStorage.getItem('unique_id')) {
      var time = localStorage.getItem('unique_id');
    }

    if (this.is_login) {
      this.login_user_id = localStorage.getItem('user_id');
    }

    if (!!this.login_user_id && !!time) {
      var data = { 'unique_id': time, 'id': this.login_user_id }
      this.CartService.getUserCartData(data).subscribe(resultArray => {
        var result = resultArray;
        if (result.status === 'success') {
          this.cart_data = true;
          this.cart_list = result.carts;
          this.cart_products_length = this.cart_list.length;
          this.order_total = 0;
          this.discount_off = 0;
          this.grand_total = 0;
          this.deliveryCharge = 0.00;
          this.priceArray = [];
          this.cart_list.forEach(element_new => {
            element_new.priceArray = [];
            element_new.cart_options_menu.forEach(element => {
              element_new.priceArray.push(parseFloat(element.menu_items.menucategory.price).toFixed(2));
              element.price = parseFloat(element.menu_items.menucategory.price).toFixed(2);
            });
            var maxPriceOrder_total = Math.max.apply(null, element_new.priceArray);

            if (maxPriceOrder_total > 0) {
              this.order_total = (parseFloat(maxPriceOrder_total) * parseInt(element_new.quantity)) + parseFloat(this.order_total);
              this.order_total = parseFloat(this.order_total).toFixed(2);
              //this.discount_off = (parseFloat(element.price)*20 * parseInt(element.quantity))/100 + parseFloat(this.discount_off); // 20% off as per html 
            } else {
              this.order_total = 0;
            }

            this.discount_off = parseFloat(this.discount_off).toFixed(2);
            console.log('maxPriceOrder_total', maxPriceOrder_total);
            element_new.cart_options_extra.forEach(element => {
              this.order_total = (parseFloat(element.extra.price) * parseInt(element_new.quantity)) + parseFloat(this.order_total);
              this.order_total = parseFloat(this.order_total).toFixed(2);
              //this.discount_off = (parseFloat(element.price)*20 * parseInt(element.quantity))/100 + parseFloat(this.discount_off); // 20% off as per html 
              this.discount_off = parseFloat(this.discount_off).toFixed(2);
            });
          });
          this.grand_total = parseFloat(this.order_total) + parseFloat(this.deliveryCharge);
          this.grand_total = parseFloat(this.grand_total).toFixed(2);
          // this.max_price_apply =  Math.max.apply(null,this.priceArray);
          if (localStorage.getItem('number_of_people')) {
            const people = localStorage.getItem('number_of_people');
            this.minShisha = Math.floor(parseInt(people) / 2);
            if (this.minShisha === '0') {
              this.minShisha = this.minShisha + 1;
            }
          }

          this.admin_charge = 0.5 * this.cart_products_length;
          this.admin_charge = parseFloat(this.admin_charge).toFixed(2);
          this.grand_total = parseFloat(this.grand_total) + parseFloat(this.admin_charge);

          if (this.cart_products_length >= this.minShisha) {
            $('#shishaMessageDisplayNew').css('display', 'none');
            $('#checkout_button_validation a').removeClass('disabled');
            this.shishaMessageDispaly = '';
            // this.hideCartValidationPopUp(); 
            $('#checkoutValidationPopup').hide();
          } else {
            //            var shishaCalculation = this.minShisha - this.cart_products_length;
            //            var menuUrl = this.router.url; 
            //            var regExpmenu ='/menu/'+'.*$';
            //            if(menuUrl.match(regExpmenu) ){
            //              $('#checkoutValidationPopup').show();
            //            }else{
            //              $('#checkoutValidationPopup').hide();
            //            }
            //            this.shishaMessageDispaly = 'You must place min of '+ this.minShisha + ' orders';
            //            $('#shishaMessageDisplayNew').css('display','block');
            $('#checkout_button_validation a').addClass('disabled')
          }

          setTimeout(() => {
            this.cart_list.forEach(element_new => {
              var diplay = false;
              element_new.cart_options_menu.forEach(element => {
                var priceId = '#price' + element.id;
                if (element.price == Math.max.apply(null, element_new.priceArray) && diplay == false) {
                  $(priceId).show();
                  diplay = true;
                } else {
                  $(priceId).hide();
                }
              });
            })
          }, 10);


        } else {
          this.grand_total = 0.00;
          this.order_total = 0.00;
          this.cart_list = [];
          this.cart_products_length = this.cart_list.length;
          this.cart_data = false;
        }
      })
    } else {
      this.grand_total = 0.00;
      this.order_total = 0.00;
      this.cart_list = [];
      this.cart_products_length = this.cart_list.length;
      this.cart_data = false;

    }

  }

  getVenueByZipcode() {

    this.displayVenuesText = true;
    this.displayText = false;

    if (!!localStorage.getItem('searchZipCode')) {
      this.searchZipCode = localStorage.getItem('searchZipCode');
      this.searchZipCodeDisplay = CommonFunction.removeUnderScoreAddSpace(this.searchZipCode);
      var dataText = this.searchZipCodeDisplay;
      this.searchVenueName = '';
    }

    if (!!localStorage.getItem('search_venue_name')) {
      this.searchZipCodeDisplay = '';
      this.searchZipCode = localStorage.getItem('search_venue_name');
      this.searchVenueName = localStorage.getItem('search_venue_name');
      var dataText = this.searchVenueName;
    }

    this.VenuelistService.getVenueByZipcode(dataText).subscribe(resultArray => {
      var result = resultArray;
      if (result.status === 'success') {
        if (result.venue.length > 0) {
          this.venueList = result.venue;
          this.currentCity = this.venueList[0].area;
          this.totalVenue = this.venueList.length;
          // result.venue.forEach(element => {
          //   var placeID = 'ChIJN1t_tDeuEmsRUsoyG83frY4'; // this is google rating
          //  // element.rating = this.getRatingFromPlaceId(placeID);
          // });
          // this.success = true;
          if (this.venueList.length <= 1) {
            $('.venuelist').addClass('ht-100vh');
          } else {
            $('.venuelist').addClass('ht-auto');
          }

        } else {
          //this.message = 'Venue not found';
          //this.failed = true;
          // setTimeout(() => {
          //   this.goToHome();
          // }, 2000);
        }
      } else {
        this.totalVenue = 0;
        this.currentCity = '';
        //this.message = result.message;
        //this.failed = true;
        // setTimeout(() => {
        //   this.goToHome();
        // }, 2000);
      }
    })
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  editItemFromCart(cart_Id) {
    $('.custom-control-input').prop('checked', false);
    $('#updatetoCartButtonPopup').show();
    $('#addtoCartButtonPopup').hide();
    $('#updateItemFormReSetButtonPopup').click(); // reset form 
    localStorage.setItem('editCart_id', cart_Id);
    this.CartService.editCustomerCartData(cart_Id).subscribe(resultArray => {
      var result = resultArray;
      var editData = result.data;
      if (result.status === 'success') {
        editData.menu.forEach(element => {
          if (!!element) {
            $('#item' + element).click(); // set menu data when edit call
            $('#item' + element).prop('checked', true);
          }
        })
        editData.extra.forEach(element => {
          if (!!element) {
            $('#item_extra' + element).click(); // set extra data when edit call
            $('#item_extra' + element).prop('checked', true);
          }
        })

      } else {

      }
    })
  }

}
