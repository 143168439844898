import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// Import the Http Module and our Data Service
import { HttpModule , BrowserXhr } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './cart/cart.component';
import { RouterModule } from '@angular/router';
//import 'rxjs/Observable';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//import {  NgProgressBrowserXhr } from 'ngx-progressbar';

// firebase facebook and gmail login
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

import { CartService } from './services/cart/cart.service';
import { VenuelistService } from './services/venuelist/venuelist.service';

import { AuthService } from './core/auth.service';

import { NgxPayPalModule } from 'ngx-paypal';


import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //NgProgressModule,
    NgProgressModule,
    NgProgressRouterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    NgxPayPalModule,
  ],
  providers: [CartService,AuthService,VenuelistService,CookieService,
   //{ provide: BrowserXhr, useClass: NgProgressBrowserXhr } ,
  ],
  bootstrap: [AppComponent],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class AppModule { }
